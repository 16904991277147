<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">订单详情</div>
		</div>
        <div class="context_box">
            <div class="context_list"><span>订单编号：</span><p>{{orderNo}}</p></div>
            <div class="context_list"><span>下单时间：</span><p>{{placeTime}}</p></div>
            <div class="context_list"><span>订单状态：</span><p>{{supplierOrderStatus}}</p></div>
            <div class="context_list"><span>订单积分：</span><p>{{dinddjf}}</p></div>
            <div class="context_list"><span>运费积分：</span><p>{{yunfjf}}</p></div>
            <div class="context_list"><span>应付积分：</span><p>{{yingfjf}}</p></div>
            <div class="context_list"><span>实付积分：</span><p>{{shifjf}}</p></div>
            <div class="context_list"><span>收货人：</span><p>{{receiverName}}</p></div>
            <div class="context_list"><span>收货地址：</span><p>{{receiverAddress}}</p></div>
            <div class="context_list"><span>手机号：</span><p>{{receiverMobile}}</p></div>
            <div class="context_list"><span>留言备注：</span><p>{{remarks}}</p></div>
        </div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" ref="elTable" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="goodsName" label="商品名称"></el-table-column>
				<el-table-column prop="goodsSku" label="商品SKU"></el-table-column>
				<el-table-column prop="num" label="数量"></el-table-column>
				<el-table-column prop="goodsIntegral" label="实付积分" ></el-table-column>
				<el-table-column prop="goodsAmount" label="商品金额" ></el-table-column>
				<el-table-column prop="freightIntegral" label="运费积分" ></el-table-column>
				<el-table-column prop="supplierStatus" label="状态" >
					<template slot-scope="scope">
						<span>{{scope.row.supplierStatus == 0 ? '待发货' : scope.row.supplierStatus == 1 ? '待收货' : scope.row.supplierStatus == 2 ? '已收货' : '退货'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="expressCompany" label="快递公司" ></el-table-column>
				<el-table-column prop="expressNumber" label="快递单号" ></el-table-column>
			</el-table>
		</div>

	</div>
</template>

<script>
	import {
		supplierorderDetail 
	} from "@/api/platform/index";
	export default {
		name: "supplierorderDetail",
		data() {
			return {
				data: [],
				id:'',
                orderNo:'',
                placeTime:'',
                supplierOrderStatus:'',
                dinddjf:'',
                yunfjf:'',
                yingfjf:'',
                shifjf:'',
                receiverName:'',
                receiverAddress:'',
                receiverMobile:'',
                remarks:'',
			};
		},
		mounted() {
			let that = this;
            that.id = this.$route.params.parentId;
			that.getData();
		},
		created() {
		},
		methods: {
			//获取列表
			getData() {
				supplierorderDetail({
					id: this.id,
				}).then(res => {
                    if(res.code  == 200){
					    this.data = res.data.goodsList;
					    this.orderNo = res.data.orderNo;
					    this.placeTime = res.data.placeTime;
					    this.supplierOrderStatus = res.data.supplierOrderStatus;
					    this.dinddjf = res.data.dinddjf;
					    this.yunfjf = res.data.yunfjf;
					    this.yingfjf = res.data.yingfjf;
					    this.shifjf = res.data.shifjf;
					    this.receiverName = res.data.receiverName;
					    this.receiverAddress = res.data.receiverAddress;
					    this.receiverMobile = res.data.receiverMobile;
					    this.remarks = res.data.remarks;
                    }else{
                        this.$message.error(res.msg)
                    }
				})
			},
		}
	}
</script>

<style scoped>
.table-content {margin-top: 20px;}
.active {color: #049EC4;}
.form_box{padding:0 50px;}
.avatar{width:300px;}
.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
.flex{display:flex;align-items:center;justify-content:space-between;}

.meunTop{display:flex;align-items:center;margin-bottom:20px;}
.meunLine{width:3px;height:20px;background-color:#FFA218;}
.meunTitle{font-size:20px;margin-left:10px;}
.el-input__inner{
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}
.search .el-form-item{
    margin-bottom: 0;
}
.context_box{
    border-bottom: 1px solid #BBBBBB;
    box-sizing: border-box;
    padding: 0 20px 20px;
}
.context_list{
    display: flex;
    align-content: center;
    justify-items: flex-start;
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
}
.context_list span{
    text-align: right;
    width: 80px;
}
</style>
